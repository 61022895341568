import React from "react";
import ThemeSwitcher from "../ThemeSwitcher";
import { Link } from "react-router-dom";
import { categoryService } from "../../services/categoryService";
import useApi from "../../hooks/useApi";
import { useCartContext } from "../../contexts/CartContext";

const NavbarSimple = () => {
	const { openCart, getCartQuantity } = useCartContext();

	const { data: categories, isLoading: categoriesLoading } = useApi(
		["categories"],
		categoryService.getCategories
	);

	const renderCategories = (categories) => {
		return categories.map((category) => (
			<div style={{ minWidth: "170px" }} key={category.id}>
				{category?.subcategories && category?.subcategories?.length > 0 ? (
					<>
						<div className="h6">{category?.name}</div>
						<ul className="nav flex-column gap-2 mt-n2">
							{category?.subcategories?.map((subcategory) => (
								<li className="d-flex w-100 pt-1" key={subcategory.id}>
									<a
										className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"
										href={`/category/${subcategory.slug}`} // Adjust the URL structure as needed
									>
										{subcategory.name}
									</a>
								</li>
							))}
						</ul>
					</>
				) : (
					<div className="h6 m-0">
						<a
							className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"
							href={`/category/${category?.slug}`} // Adjust the URL structure as needed
						>
							{category?.name}
						</a>
					</div>
				)}
			</div>
		));
	};

	return (
		<header
			className="navbar navbar-expand navbar-sticky sticky-top d-block bg-body z-fixed py-1 py-lg-0 py-xl-1 px-0"
			data-sticky-element=""
		>
			<div className="container justify-content-start py-2 py-lg-3">
				{/* <!-- Navbar brand (Logo) --> */}
				<Link
					className="navbar-brand fs-2 p-0 pe-lg-2 pe-xxl-0 me-0 me-sm-3 me-md-4 me-xxl-5"
					to="/"
				>
					OnlineStore
				</Link>

				{/* <!-- Categories dropdown visible on screens > 991px wide (lg breakpoint) --> */}
				<div
					className="dropdown d-none d-lg-block w-100 me-4"
					style={{ maxWidth: "200px" }}
				>
					<button
						type="button"
						className="btn btn-lg btn-secondary w-100 border-0 rounded-pill"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i className="ci-grid fs-lg me-2 ms-n1"></i>
						Kateqoriyalar
						<i className="ci-chevron-down fs-lg me-2 ms-auto me-n1"></i>
					</button>
					<div
						className="dropdown-menu rounded-4 p-4"
						style={{ "--cz-dropdown-spacer": ".75rem", marginLeft: "-75px" }}
					>
						<div className="d-flex gap-4">
							{categories?.length > 0 ? (
								renderCategories(categories)
							) : (
								<p>Loading categories...</p>
							)}
						</div>
					</div>
				</div>

				{/* <!-- Search bar visible on screens > 768px wide (md breakpoint) --> */}
				<div className="position-relative w-100 d-none d-md-block me-3 me-xl-4">
					<input
						type="search"
						className="form-control form-control-lg rounded-pill"
						placeholder="Məhsul axtarışı"
						aria-label="Məhsul axtarışı"
					/>
					<button
						type="button"
						className="btn btn-icon btn-ghost fs-lg btn-secondary text-bo border-0 position-absolute top-0 end-0 rounded-circle mt-1 me-1"
						aria-label="Search button"
					>
						<i className="ci-search"></i>
					</button>
				</div>

				{/* <!-- Delivery options toggle visible on screens > 1200px wide (xl breakpoint) --> */}
				{/* <div className="nav me-4 me-xxl-5 d-none d-xl-block">
					<a
						className="nav-link flex-column align-items-start animate-underline p-0"
						href="#deliveryOptions"
						data-bs-toggle="offcanvas"
						aria-controls="deliveryOptions"
					>
						<div className="h6 fs-sm mb-0">Delivery</div>
						<div className="d-flex align-items-center fs-sm fw-normal text-body">
							<span className="animate-target text-nowrap">
								Set your address
							</span>
							<i className="ci-chevron-down fs-base ms-1"></i>
						</div>
					</a>
				</div> */}

				{/* <!-- Button group --> */}
				<div className="d-flex align-items-center gap-md-1 gap-lg-2 ms-auto">
					{/* <!-- Theme switcher (light/dark/auto) --> */}
					<ThemeSwitcher />
					{/* <div className="dropdown">
						<button
							type="button"
							className="theme-switcher btn btn-icon btn-lg btn-outline-secondary fs-lg border-0 rounded-circle animate-scale"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							aria-label="Toggle theme (light)"
						>
							<span className="theme-icon-active d-flex animate-target">
								<i className="ci-sun"></i>
							</span>
						</button>
						<ul
							className="dropdown-menu"
							style={{ "--cz-dropdown-min-width": "9rem" }}
						>
							<li>
								<button
									type="button"
									className="dropdown-item active"
									data-bs-theme-value="light"
									aria-pressed="true"
								>
									<span className="theme-icon d-flex fs-base me-2">
										<i className="ci-sun"></i>
									</span>
									<span className="theme-label">Light</span>
									<i className="item-active-indicator ci-check ms-auto"></i>
								</button>
							</li>
							<li>
								<button
									type="button"
									className="dropdown-item"
									data-bs-theme-value="dark"
									aria-pressed="false"
								>
									<span className="theme-icon d-flex fs-base me-2">
										<i className="ci-moon"></i>
									</span>
									<span className="theme-label">Dark</span>
									<i className="item-active-indicator ci-check ms-auto"></i>
								</button>
							</li>
							<li>
								<button
									type="button"
									className="dropdown-item"
									data-bs-theme-value="auto"
									aria-pressed="false"
								>
									<span className="theme-icon d-flex fs-base me-2">
										<i className="ci-auto"></i>
									</span>
									<span className="theme-label">Auto</span>
									<i className="item-active-indicator ci-check ms-auto"></i>
								</button>
							</li>
						</ul>
					</div> */}

					{/* <!-- Search toggle button visible on screens < 768px wide (md breakpoint) --> */}
					<button
						type="button"
						className="btn btn-icon fs-xl btn-outline-secondary border-0 rounded-circle animate-shake d-md-none"
						data-bs-toggle="collapse"
						data-bs-target="#searchBar"
						aria-controls="searchBar"
						aria-label="Toggle search bar"
					>
						<i className="ci-search animate-target"></i>
					</button>

					{/* <!-- Delivery options button visible on screens < 1200px wide (xl breakpoint) --> */}
					{/* <button
						type="button"
						className="btn btn-icon fs-lg btn-outline-secondary border-0 rounded-circle animate-scale d-xl-none"
						data-bs-toggle="offcanvas"
						data-bs-target="#deliveryOptions"
						aria-controls="deliveryOptions"
						aria-label="Toggle delivery options offcanvas"
					>
						<i className="ci-map-pin animate-target"></i>
					</button> */}

					{/* <!-- Account button visible on screens > 768px wide (md breakpoint) --> */}
					<a
						className="btn btn-icon fs-lg btn-outline-secondary border-0 rounded-circle animate-shake d-none d-md-inline-flex"
						href="account-signin.html"
					>
						<i className="ci-user animate-target"></i>
						<span className="visually-hidden">Account</span>
					</a>

					{/* <!-- Cart button --> */}
					<button
						type="button"
						className="btn btn-icon fs-xl btn-outline-secondary position-relative border-0 rounded-circle animate-scale"
						onClick={openCart}
						aria-label="Shopping cart"
					>
						{getCartQuantity() > 0 && (
							<span
								className="position-absolute top-0 start-100 badge fs-xs text-bg-primary rounded-pill ms-n3 z-2"
								style={{
									"--cz-badge-padding-y": ".25em",
									"--cz-badge-padding-x": ".42em",
								}}
							>
								{getCartQuantity()}
							</span>
						)}
						<i className="ci-shopping-cart animate-target"></i>
					</button>
				</div>
			</div>

			{/* <!-- Search collapse available on screens < 768px wide (md breakpoint) --> */}
			<div className="collapse d-md-none" id="searchBar">
				<div className="container pt-2 pb-3">
					<div className="position-relative">
						<i className="ci-search position-absolute top-50 translate-middle-y d-flex fs-lg ms-3"></i>
						<input
							type="search"
							className="form-control form-icon-start rounded-pill"
							placeholder="Məhsul axtarışı"
							data-autofocus="collapse"
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default NavbarSimple;

import { useNavigate } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";

const CartItem = ({ product }) => {
	const { removeFromCart, increaseQuantity, decreaseQuantity, closeCart } =
		useCartContext();
	const navigate = useNavigate();

	const handleClick = () => {
		closeCart();
		navigate(`/products/${product.id}`);
	};

	return (
		<div className="d-flex align-items-center">
			<div className="flex-shrink-0 cursor-pointer" onClick={handleClick}>
				<img
					className="object-fit-contain"
					src={
						product?.image || product?.images?.[0]
							? `${product?.image || product?.images?.[0]}thumbnail.webp`
							: "/assets/img/default.jpg"
					}
					width="110"
					alt={product?.name}
				/>
			</div>
			<div className="w-100 min-w-0 ps-2 ps-sm-3">
				<h5 className="d-flex animate-underline mb-2">
					<div
						className="d-block fs-sm fw-medium text-truncate animate-target cursor-pointer"
						onClick={handleClick}
					>
						{product?.name}
					</div>
				</h5>
				<div className="h6 pb-1 mb-2">
					{Number(product?.price).toFixed(2)} AZN
				</div>
				<div className="d-flex align-items-center justify-content-between">
					<div className="count-input rounded-2">
						<button
							type="button"
							className="btn btn-icon btn-sm"
							data-decrement=""
							aria-label="Decrement quantity"
							onClick={() => decreaseQuantity(product?.id)}
						>
							<i className="ci-minus"></i>
						</button>
						<input
							type="number"
							className="form-control form-control-sm"
							value={product?.quantity}
							readOnly
						/>
						<button
							type="button"
							className="btn btn-icon btn-sm"
							data-increment=""
							aria-label="Increment quantity"
							onClick={() => increaseQuantity(product?.id)}
						>
							<i className="ci-plus"></i>
						</button>
					</div>
					<button
						type="button"
						className="btn-close fs-sm"
						data-bs-toggle="tooltip"
						data-bs-title="Remove"
						aria-label="Remove from cart"
						onClick={() => removeFromCart(product?.id)}
					></button>
				</div>
			</div>
		</div>
	);
};

export default CartItem;

import React from "react";
import { useCartContext } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import CartPageItem from "../components/cart/CartPageItem";

const CartPage = () => {
	const {
		cart,
		decreaseQuantity,
		increaseQuantity,
		removeFromCart,
		clearCart,
		getCartTotal,
	} = useCartContext();

	return (
		<>
			{/* Breadcrumb */}
			<nav
				className="container pt-1 pt-md-0 my-3 my-md-4"
				aria-label="breadcrumb"
			>
				<ol className="breadcrumb mb-0">
					<li className="breadcrumb-item">
						<a href="home-grocery.html">Home</a>
					</li>
					<li className="breadcrumb-item">
						<a href="shop-catalog-grocery.html">Shop</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						Cart
					</li>
				</ol>
			</nav>

			{/* Items in the cart + Order summary */}
			<section className="container pb-5 mb-2 mb-md-3 mb-lg-4 mb-xl-5">
				<h1 className="h3 mb-4">Alış-veriş səbəti</h1>
				<div className="row">
					{/* Items list + Delivery / pickup tabs */}
					<div className="col-lg-8">
						<div className="pe-lg-2 pe-xl-3 me-xl-3">
							{/* Delivery / pickup tabs */}
							{/* <ul
								className="nav nav-pills nav-justified flex-nowrap flex-column flex-sm-row pb-md-2 mb-4"
								role="tablist"
							>
								<li className="nav-item" role="presentation">
									<button
										type="button"
										className="nav-link justify-content-start text-start p-3 px-md-4 px-lg-3 px-xl-4 rounded-4 active"
										id="cart-delivery-tab"
										data-bs-toggle="pill"
										data-bs-target="#delivery"
										role="tab"
										aria-controls="delivery"
										aria-selected="true"
									>
										<span
											className="d-flex align-items-center justify-content-center flex-shrink-0 bg-success-subtle text-dark-emphasis rounded-circle"
											style={{ width: "3rem", height: "3rem" }}
										>
											<i className="ci-shopping-bag fs-xl"></i>
										</span>
										<span className="ps-3">
											<span className="h6 d-block mb-1">Delivery</span>
											<span className="fs-xs fw-normal text-body lh-base">
												All products are available
											</span>
										</span>
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										type="button"
										className="nav-link justify-content-start text-start p-3 px-md-4 px-lg-3 px-xl-4 rounded-4"
										id="cart-pickup-tab"
										data-bs-toggle="pill"
										data-bs-target="#pickup"
										role="tab"
										aria-controls="pickup"
										aria-selected="false"
									>
										<span
											className="d-flex align-items-center justify-content-center flex-shrink-0 bg-info-subtle text-dark-emphasis rounded-circle"
											style={{ width: "3rem", height: "3rem" }}
										>
											<i className="ci-package fs-xl"></i>
										</span>
										<span className="ps-3">
											<span className="h6 d-block mb-1">Pickup</span>
											<span className="fs-xs fw-normal text-body lh-base">
												All products are available
											</span>
										</span>
									</button>
								</li>
							</ul> */}

							{/* Table of items */}
							<table className="table position-relative z-2 mb-4">
								<thead>
									<tr>
										<th scope="col" className="fs-sm fw-normal py-3 ps-0">
											<span className="text-body">Məhsul</span>
										</th>
										<th
											scope="col"
											className="text-body fs-sm fw-normal py-3 d-none d-xl-table-cell"
										>
											<span className="text-body">Qiymət</span>
										</th>
										<th
											scope="col"
											className="text-body fs-sm fw-normal py-3 d-none d-md-table-cell"
										>
											<span className="text-body">Sayı</span>
										</th>
										<th
											scope="col"
											className="text-body fs-sm fw-normal py-3 d-none d-md-table-cell"
										>
											<span className="text-body">Məbləğ</span>
										</th>
										<th scope="col" className="py-0 px-0">
											<div className="nav justify-content-end">
												<button
													type="button"
													className="nav-link d-inline-block text-decoration-underline text-nowrap py-3 px-0"
													onClick={clearCart}
												>
													Səbəti təmizlə
												</button>
											</div>
										</th>
									</tr>
								</thead>
								<tbody className="align-middle">
									{/* Item */}
									{cart?.map((product, index) => (
										<CartPageItem
											key={index}
											product={product}
											decreaseQuantity={decreaseQuantity}
											increaseQuantity={increaseQuantity}
											removeFromCart={removeFromCart}
										/>
									))}
								</tbody>
							</table>

							<div className="nav position-relative z-2 mb-4 mb-lg-0">
								<Link
									className="nav-link animate-underline px-0"
									to="/products"
								>
									<i className="ci-chevron-left fs-lg me-1"></i>
									<span className="animate-target">Alışverişə davam et</span>
								</Link>
							</div>
						</div>
					</div>

					{/* Order summary (sticky sidebar) */}
					<aside className="col-lg-4" style={{ marginTop: "-115px" }}>
						<div
							className="position-sticky top-0"
							style={{ paddingTop: "115px" }}
						>
							{getCartTotal() > 20 && (
								<div
									className="alert alert-success text-dark-emphasis fs-sm border-0 rounded-4 mb-4"
									role="alert"
								>
									Təbriklər 🎉 Səbətinizə{" "}
									<span className="fw-semibold">20 ₼</span> -dan çox məhsul
									əlavə etdiyiniz üçün{" "}
									<span className="fw-semibold">Çatdırılma</span> pulsuzdur.
								</div>
							)}
							<h2 className="h5 border-bottom pb-4 mb-4 me-3">Səbət cəmi</h2>
							<div className="tab-content">
								{/* Delivery tab */}
								<div
									className="tab-pane show active"
									id="delivery"
									role="tabpanel"
									aria-labelledby="cart-delivery-tab"
								>
									<ul className="list-unstyled fs-sm gap-3 mb-0">
										<li className="d-flex justify-content-between">
											Ümumi (8 məhsul):
											<span className="text-dark-emphasis fw-medium">
												{getCartTotal()} ₼
											</span>
										</li>
										<li className="d-flex justify-content-between">
											Endirim:
											<span className="text-danger fw-medium">-5 ₼</span>
										</li>
										<li className="d-flex justify-content-between">
											Çatdırılma:
											<span className="text-dark-emphasis fw-medium">
												Pulsuz
											</span>
										</li>
									</ul>
									<div className="border-top pt-4 mt-4">
										<div className="d-flex justify-content-between mb-3">
											<span className="fs-sm">Ümumi məbləğ:</span>
											<span className="h5 mb-0">{getCartTotal()} ₼</span>
										</div>
										<Link
											className="btn btn-lg btn-primary w-100"
											to="/checkout"
										>
											Ödənişə keç
											<i className="ci-chevron-right fs-lg ms-1 me-n1"></i>
										</Link>
									</div>
								</div>

								{/* Pickup tab */}
								<div
									className="tab-pane"
									id="pickup"
									role="tabpanel"
									aria-labelledby="cart-pickup-tab"
								>
									<ul className="list-unstyled fs-sm gap-3 mb-0">
										<li className="d-flex justify-content-between">
											Subtotal (8 items):
											<span className="text-dark-emphasis fw-medium">
												$71.70
											</span>
										</li>
										<li className="d-flex justify-content-between">
											Saving:
											<span className="text-danger fw-medium">-$2.79</span>
										</li>
									</ul>
									<div className="border-top pt-4 mt-4">
										<div className="d-flex justify-content-between mb-3">
											<span className="fs-sm">Estimated total:</span>
											<span className="h5 mb-0">$68.91</span>
										</div>
										<a
											className="btn btn-lg btn-primary w-100"
											href="checkout-v2-pickup.html"
										>
											Proceed to checkout
											<i className="ci-chevron-right fs-lg ms-1 me-n1"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
			</section>
		</>
	);
};

export default CartPage;

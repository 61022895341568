import React, { useEffect, useState } from "react";
import ProductCard from "../components/product/ProductCard";
import useApi from "../hooks/useApi";
import { productService } from "../services/productService";
import { categoryService } from "../services/categoryService";
import { brandService } from "../services/brandService";

const CategoryFilter = ({ category }) => {
	return (
		<li className="nav d-block pt-2 mt-1">
			<a className="nav-link animate-underline fw-normal p-0" href="#!">
				<span className="animate-target text-truncate me-3">
					{category.name}
				</span>
				<span className="text-body-secondary fs-xs ms-auto">
					{category.count}
				</span>
			</a>
		</li>
	);
};

const PriceRangeFilter = () => {
	return (
		<div className="d-flex align-items-center">
			<div className="position-relative w-50">
				<i className="ci-dollar-sign position-absolute top-50 start-0 translate-middle-y ms-3"></i>
				<input
					type="number"
					className="form-control form-icon-start"
					min="0"
					placeholder="Min"
				/>
			</div>
			<i className="ci-minus text-body-emphasis mx-2"></i>
			<div className="position-relative w-50">
				<i className="ci-dollar-sign position-absolute top-50 start-0 translate-middle-y ms-3"></i>
				<input
					type="number"
					className="form-control form-icon-start"
					min="0"
					placeholder="Max"
				/>
			</div>
		</div>
	);
};

const FilterSection = ({ title, children }) => (
	<div className="w-100 border rounded p-3 p-xl-4 mb-3 mb-xl-4">
		<h4 className="h6 mb-2">{title}</h4>
		{children}
	</div>
);

// Main CatalogPage Component
const CatalogPage = () => {
	const [selectedFilters, setSelectedFilters] = useState([]);

	const { data: products, isLoading: productsLoading } = useApi(
		["products"],
		() => productService.getProducts({ params: selectedFilters })
	);
	const { data: categories, isLoading: categoriesLoading } = useApi(
		["categories"],
		() => categoryService.getCategories()
	);
	const { data: brands, isLoading: brandsLoading } = useApi(["brands"], () =>
		brandService.getBrands()
	);

	const toggleFilter = (filter) => {
		setSelectedFilters((prev) =>
			prev.includes(filter)
				? prev.filter((f) => f !== filter)
				: [...prev, filter]
		);
	};

	return (
		<>
			{/* Breadcrumb */}
			<nav className="container pt-3 my-3" aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<a href="/">Home</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						Catalog with sidebar filters
					</li>
				</ol>
			</nav>

			{/* Page Title */}
			<h1 className="h3 container mb-4">Shop Catalog</h1>

			{/* Selected Filters + Sorting */}
			<section className="container mb-4">
				<div className="row">
					<div className="col-lg-9">
						<div className="d-md-flex align-items-start">
							<div className="h6 fs-sm fw-normal text-nowrap translate-middle-y mt-3 mb-0 me-4">
								<span className="fw-semibold">732</span> məhsul tapıldı
							</div>
							<div className="d-flex flex-wrap gap-2">
								{selectedFilters.map((filter, index) => (
									<button
										key={index}
										onClick={() => toggleFilter(filter)}
										type="button"
										className="btn btn-sm btn-secondary"
									>
										<i className="ci-close fs-sm ms-n1 me-1"></i>
										{filter}
									</button>
								))}
								<button
									onClick={() => setSelectedFilters([])}
									className="btn btn-sm btn-secondary bg-transparent border-0 text-decoration-underline px-0 ms-2"
								>
									Təmizlə
								</button>
							</div>
						</div>
					</div>
					<div className="col-lg-3 mt-3 mt-lg-0">
						<div className="d-flex align-items-center justify-content-lg-end text-nowrap">
							<label className="form-label fw-semibold mb-0 me-2">
								Sıralama:
							</label>
							<div style={{ width: "220px" }}>
								<select className="form-select border-0 rounded-0 px-1">
									<option value="Price: Low to High">
										Qiymət: Aşağıdan Yuxarıya
									</option>
									<option value="Price: High to Low">
										Qiymət: Yuxarıdan Aşağıya
									</option>
									<option value="Newest Arrivals">Ən Yeni Gələnlər</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Products Grid + Sidebar */}
			<section className="container pb-5 mb-sm-2 mb-md-3 mb-lg-4 mb-xl-5">
				<div className="row">
					{/* Sidebar */}
					<aside className="col-lg-3">
						<div className="offcanvas-lg offcanvas-start" id="filterSidebar">
							<div className="offcanvas-header py-3">
								<h5 className="offcanvas-title">Filterlər</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="offcanvas"
									data-bs-target="#filterSidebar"
									aria-label="Close"
								></button>
							</div>
							<div className="offcanvas-body flex-column pt-2 py-lg-0">
								{/* Status */}
								<FilterSection title="Status">
									<button
										onClick={() => toggleFilter("Sale")}
										className="btn btn-sm btn-outline-secondary"
									>
										Sale
									</button>
								</FilterSection>

								{/* Categories */}
								<FilterSection title="Kategoriyalar">
									<ul className="list-unstyled d-block m-0">
										{categories?.map((category, index) => (
											<CategoryFilter key={index} category={category} />
										))}
									</ul>
								</FilterSection>

								{/* Price Range */}
								<FilterSection title="Qiymət">
									<PriceRangeFilter />
								</FilterSection>

								{/* Brands */}
								{brands && (
									<FilterSection title="Brendlər">
										{brands?.map((brand, index) => (
											<div key={index} className="form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id={brand.name}
													onChange={() => toggleFilter(brand.name)}
												/>
												<label
													className="form-check-label"
													htmlFor={brand.name}
												>
													{brand.name}
												</label>
											</div>
										))}
									</FilterSection>
								)}
							</div>
						</div>
					</aside>

					{/* Product Grid */}
					<div className="col-lg-9">
						<div className="row row-cols-2 row-cols-md-3 g-4 pb-3 mb-3">
							{products?.map((product, index) => (
								<div className="col" key={index}>
									<ProductCard product={product} />
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Filter offcanvas toggle that is visible on screens < 992px wide (lg breakpoint) --> */}
			<button
				type="button"
				className="fixed-bottom z-sticky w-100 btn btn-lg btn-dark border-0 border-top border-light border-opacity-10 rounded-0 pb-4 d-lg-none"
				data-bs-toggle="offcanvas"
				data-bs-target="#filterSidebar"
				aria-controls="filterSidebar"
				data-bs-theme="light"
			>
				<i className="ci-filter fs-base me-2"></i>
				Filterlər
			</button>
		</>
	);
};

export default CatalogPage;

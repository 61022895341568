import React, { useState } from "react";
import { useCartContext } from "../../contexts/CartContext";
import CartItem from "./CartItem";
import { Link, useNavigate } from "react-router-dom";

const EmptyCart = ({ closeCart }) => {
	const navigate = useNavigate();

	const handleClick = () => {
		closeCart();
		navigate("/");
	};

	return (
		<div className="offcanvas-body text-center">
			<svg
				className="d-block mx-auto mb-4"
				xmlns="http://www.w3.org/2000/svg"
				width="60"
				viewBox="0 0 29.5 30"
			></svg>
			<h6 className="mb-2">Sizin alış-veriş səbətiniz hazırda boşdur!</h6>
			<p className="fs-sm mb-4">
				Məhsullarımızın geniş çeşidini araşdırın və alışınızı davam etdirmək
				üçün səbətinizə məhsullar əlavə edin.
			</p>
			<button className="btn btn-primary rounded-pill" onClick={handleClick}>
				Alış-verişə davam et
			</button>
		</div>
	);
};

const FreeShippingProgress = () => {
	return (
		<>
			<p className="fs-sm">
				<span className="text-dark-emphasis fw-semibold">183 AZN</span>{" "}
				dəyərində məhsul əlavə edin,{" "}
				<span className="text-dark-emphasis fw-semibold">
					Pulsuz Çatdırılma
				</span>{" "}
				qazanın.
			</p>
			<div
				className="progress w-100"
				role="progressbar"
				aria-label="Pulsuz çatdırılma irəliləyişi"
				aria-valuenow="75"
				aria-valuemin="0"
				aria-valuemax="100"
				style={{ height: "4px" }}
			>
				<div
					className="progress-bar bg-warning rounded-pill"
					style={{ width: "75%" }}
				></div>
			</div>
		</>
	);
};

const ShoppingCart = () => {
	const {
		cart,
		removeFromCart,
		getCartTotal,
		getCartQuantity,
		isCartEmpty,
		isCartOpen,
		openCart,
		closeCart,
	} = useCartContext();

	return (
		<>
			{/* Backdrop */}
			<div
				className={`offcanvas-backdrop ${
					isCartOpen ? "show-backdrop" : "hide-backdrop"
				}`}
				onClick={closeCart}
			></div>

			{/* Shopping Cart Offcanvas */}
			<div
				className={`offcanvas offcanvas-end pb-sm-2 px-sm-2 ${
					isCartOpen ? "show" : ""
				}`}
				aria-labelledby="shoppingCartLabel"
				tabindex="-1"
				id="shoppingCart"
				style={{
					width: "500px",
					visibility: isCartOpen ? "visible" : "hidden",
					transform: isCartOpen ? "translateX(0)" : "translateX(100%)",
					transition: "transform 0.3s ease-in-out, visibility 0.3s ease-in-out",
				}}
			>
				{/* Header */}
				<div className="offcanvas-header flex-column align-items-start py-3 pt-lg-4">
					<div className="d-flex align-items-center justify-content-between w-100 mb-lg-4">
						<h4 className="offcanvas-title" id="shoppingCartLabel">
							Səbət
						</h4>
						<button
							type="button"
							className="btn-close"
							onClick={closeCart}
							aria-label="Close"
						></button>
					</div>
					{/* {!isCartEmpty() && <FreeShippingProgress />} */}
				</div>

				{/* Body */}
				{isCartEmpty() ? (
					<EmptyCart closeCart={closeCart} />
				) : (
					<>
						<div className="offcanvas-body d-flex flex-column gap-4 pt-2">
							{cart.map((product, index) => (
								<CartItem key={index} product={product} />
							))}
						</div>
						{/* Footer */}
						<div className="offcanvas-header flex-column align-items-start">
							<div className="d-flex align-items-center justify-content-between w-100 mb-3 mb-md-4">
								<span className="text-light-emphasis">Cəmi:</span>
								<span className="h6 mb-0">{getCartTotal().toFixed(2)} AZN</span>
							</div>
							<div className="d-flex w-100 gap-3">
								<Link
									className="btn btn-lg btn-secondary w-100"
									to="/cart"
									onClick={closeCart}
								>
									Səbətə bax
								</Link>
								<Link
									className="btn btn-lg btn-primary w-100"
									to="/checkout"
									onClick={closeCart}
								>
									Sifariş et
								</Link>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ShoppingCart;

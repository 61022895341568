import { publicApi } from "../config/api";

export const productService = {
	async getProducts(params) {
		const response = await publicApi.get("products", { params });
		return response.data;
	},

	async getFeaturedProducts() {
		const response = await publicApi.get("products/featured");
		return response.data;
	},

	async getProduct(id) {
		const response = await publicApi.get(`products/${id}`);
		return response.data;
	},

	async getCategories() {
		const response = await publicApi.get("categories");
		return response.data;
	},

	async getCategory(id) {
		const response = await publicApi.get(`categories/${id}`);
		return response.data;
	},
};

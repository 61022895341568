import { useQuery } from "@tanstack/react-query";

export default function useApi(
	/**
	 * React Query key.
	 * e.g. "products", "categories" or ["product", id].
	 */
	queryKey,

	/**
	 * Your fetcher function (e.g. a function that calls storeService.getProducts)
	 */
	queryFn,

	/**
	 * (Optional) React Query options (e.g. enabled, staleTime, etc.)
	 */
	options = {}
) {
	return useQuery({
		queryKey,
		queryFn: ({ queryKey, signal }) => queryFn({ queryKey, signal }),
		...options,
	});
}

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DetailsPage from "./pages/DetailsPage";
import MainLayout from "./layouts/MainLayout";
import CatalogPage from "./pages/CatalogPage";
import ScrollToTop from "./components/ScrollToTop";
import { CartProvider } from "./contexts/CartContext";
import CheckoutPage from "./pages/CheckoutPage";
import CartPage from "./pages/CartPage";
function App() {
	return (
		<BrowserRouter>
			<CartProvider>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<MainLayout />}>
						<Route path="" element={<HomePage />} />
						<Route path="home" element={<HomePage />} />
						<Route path="products/:id" element={<DetailsPage />} />
						<Route path="products" element={<CatalogPage />} />
						<Route path="checkout" element={<CheckoutPage />} />
						<Route path="cart" element={<CartPage />} />
					</Route>
				</Routes>
			</CartProvider>
		</BrowserRouter>
	);
}

export default App;

import React, { useState } from "react";

const PhoneInput = ({ value: propValue, onChange: propOnChange, ...props }) => {
	const [value, setValue] = useState(propValue || "");

	const formatPhoneNumber = (input) => {
		// Remove all non-digit characters
		const cleaned = input.replace(/\D/g, "");

		// Handle the +994 prefix
		if (cleaned.length === 0) return "";
		if (cleaned.length <= 3) return "+994";
		if (cleaned.length <= 5) return `+994 ${cleaned.slice(3, 5)}`;
		if (cleaned.length <= 8)
			return `+994 ${cleaned.slice(3, 5)} ${cleaned.slice(5, 8)}`;
		if (cleaned.length <= 10)
			return `+994 ${cleaned.slice(3, 5)} ${cleaned.slice(
				5,
				8
			)} ${cleaned.slice(8, 10)}`;
		return `+994 ${cleaned.slice(3, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(
			8,
			10
		)} ${cleaned.slice(10, 12)}`;
	};

	const handleChange = (e) => {
		const input = e.target.value;

		// If backspace is pressed and we're at a space, delete the character before it
		if (input.length < value.length && input.endsWith(" ")) {
			const newValue = input.slice(0, -1);
			setValue(newValue);
			propOnChange?.(newValue);
			return;
		}

		// Format the number
		const formattedNumber = formatPhoneNumber(input);
		setValue(formattedNumber);
		propOnChange?.(formattedNumber);
	};

	const handleKeyDown = (e) => {
		// Allow: backspace, delete, tab, escape, enter
		if (
			[46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
			// Allow: Ctrl+A, Ctrl+C, Ctrl+V
			(e.keyCode === 65 && e.ctrlKey === true) ||
			(e.keyCode === 67 && e.ctrlKey === true) ||
			(e.keyCode === 86 && e.ctrlKey === true) ||
			// Allow: home, end, left, right
			(e.keyCode >= 35 && e.keyCode <= 39)
		) {
			return;
		}
		// Block any non-number inputs except '-', '(', ')', '+', and space
		if (
			(e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
			(e.keyCode < 96 || e.keyCode > 105)
		) {
			e.preventDefault();
		}
	};

	return (
		<input
			type="tel"
			value={value}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			maxLength={17} // +994 XX XXX XX XX format length
			{...props}
		/>
	);
};

export default PhoneInput;

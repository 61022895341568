import React from "react";
import PhoneInput from "../common/PhoneInput";

const DeliveryAddress = () => {
	return (
		<>
			<h2 className="h5 mb-4">Çatdırılma məlumatları</h2>
			{/* <div className="d-flex align-items-center justify-content-between mb-4">
				<div className="d-flex align-items-center fs-sm text-dark-emphasis me-3">
					<i className="ci-map-pin fs-base text-primary me-2"></i>
					567 Cherry Souse Lane Sacramento, 95829
				</div>
				<div className="nav">
					<a
						className="nav-link text-decoration-underline text-nowrap p-0"
						href="#deliveryOptions"
						data-bs-toggle="offcanvas"
						aria-controls="deliveryOptions"
					>
						Ünvanı dəyişdir
					</a>
				</div>
			</div> */}
			<div className="row row-cols-sm-1 row-cols-md-2 g-3 g-ms-4 mb-3 mb-sm-4">
				<div className="col">
					<label htmlFor="name" className="form-label">
						Ad *
					</label>
					<input
						required
						type="text"
						className="form-control form-control-lg"
						id="name"
					/>
				</div>
				<div className="col">
					<label htmlFor="surname" className="form-label">
						Soyad *
					</label>
					<input
						required
						type="text"
						className="form-control form-control-lg"
						id="surname"
					/>
				</div>
			</div>
			<div className="row row-cols-1 g-3 g-ms-4 mb-3 mb-sm-4">
				<div className="col">
					<label htmlFor="address" className="form-label">
						Ünvan *
					</label>
					<input
						required
						type="text"
						className="form-control form-control-lg"
						id="address"
					/>
				</div>
			</div>
			<label htmlFor="phone-number" className="form-label">
				Telefon nömrəsi *
				{/* <span className="fw-normal text-body-secondary">
					(Sifarişinizlə bağlı hər hansı bir problem yaranarsa, sizinlə əlaqə
					saxlayacağıq.){" "}
				</span> */}
			</label>
			<PhoneInput
				id="phone-number"
				className="form-control form-control-lg"
				placeholder="+994 __ ___ __ __"
				required
			/>
		</>
	);
};

export default DeliveryAddress;

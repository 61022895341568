import React, { useEffect } from "react";
import ProductCard from "../product/ProductCard";
import { productService } from "../../services/productService";
import useApi from "../../hooks/useApi";
import { Link } from "react-router-dom";

const ProductsSection = ({ title, path, linkText }) => {
	const { data: products, isLoading: productsLoading } = useApi(
		["products"],
		() => productService.getProducts()
	);

	return (
		<section className="container pt-5 mt-2 mt-sm-3 mt-lg-4 mb-5">
			{/* TODO: Remove mb-5 after adding brands section */}

			{/* <!-- Heading --> */}
			<div className="d-flex align-items-center justify-content-between border-bottom pb-3 pb-md-4">
				<h2 className="h3 mb-0">{title}</h2>
				<div className="nav ms-3">
					<Link className="nav-link animate-underline px-0 py-2" to={path}>
						<span className="animate-target">{linkText}</span>
						<i className="ci-chevron-right fs-base ms-1"></i>
					</Link>
				</div>
			</div>

			{/* <!-- Product grid --> */}
			<div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 pt-4">
				{productsLoading ? (
					<div>Yüklənir...</div>
				) : (
					products?.map((product) => (
						<div className="col" key={product.id}>
							<ProductCard product={product} />
						</div>
					))
				)}
			</div>
		</section>
	);
};

export default ProductsSection;

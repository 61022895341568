import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavbarSimple from "../components/navbars/NavbarSimple";
import ShoppingCart from "../components/cart/ShoppingCart";

const MainLayout = () => {
	return (
		<>
			{/* Shopping cart offcanvas */}
			<ShoppingCart />

			<NavbarSimple />
			<main className="content-wrapper">
				<Outlet />
			</main>
			<Footer />

			{/* Back to top button */}
			<div className="floating-buttons position-fixed top-50 end-0 z-sticky me-3 me-xl-4 pb-4">
				<a
					className="btn-scroll-top btn btn-sm bg-body border-0 rounded-pill shadow animate-slide-end"
					href="#top"
				>
					Top
					<i className="ci-arrow-right fs-base ms-1 me-n1 animate-target"></i>
					<span className="position-absolute top-0 start-0 w-100 h-100 border rounded-pill z-0"></span>
					<svg
						className="position-absolute top-0 start-0 w-100 h-100 z-1"
						viewBox="0 0 62 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x=".75"
							y=".75"
							width="60.5"
							height="30.5"
							rx="15.25"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeMiterlimit="10"
						></rect>
					</svg>
				</a>
			</div>
		</>
	);
};

export default MainLayout;

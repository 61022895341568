import { Link } from "react-router-dom";

const CartPageItem = ({
	product,
	decreaseQuantity,
	increaseQuantity,
	removeFromCart,
}) => {
	return (
		<tr>
			<td className="py-3 ps-0">
				<div className="d-flex align-items-center">
					<Link
						className="position-relative flex-shrink-0"
						to={`/products/${product?.id}`}
					>
						{/* Discount */}
						{/* <span className="badge text-bg-danger position-absolute top-0 start-0">
							-$2.79
						</span> */}
						<img
							src={
								product?.image || product?.images?.[0]
									? `${product?.image || product?.images?.[0]}thumbnail.webp`
									: "/assets/img/default.jpg"
							}
							// style={{ width: "85px", height: "85px", objectFit: "contain" }}
							width="110"
							alt="Thumbnail"
						/>
					</Link>
					<div className="ps-2 ps-xl-3">
						<h5 className="lh-sm mb-2">
							<Link
								className="hover-effect-underline fs-sm fw-medium"
								to={`/products/${product?.id}`}
							>
								{product?.name}
							</Link>
						</h5>
						<ul className="list-unstyled gap-1 fs-xs mb-0">
							{/* <li>
								<span className="text-body-secondary">Portion:</span>{" "}
								<span className="text-dark-emphasis fw-medium">1kg</span>
							</li> */}
							<li className="d-xl-none d-flex gap-2">
								<span className="text-body-secondary">Qiymət:</span>{" "}
								<span className="text-dark-emphasis fw-medium">
									<span className="d-flex flex-column">
										<span>{`${Number(product?.price).toFixed(2)} ₼`}</span>
										<span>
											{product?.oldPrice != 0 && (
												<del className="text-body-tertiary fw-normal">
													{Number(product?.oldPrice).toFixed(2)} ₼
												</del>
											)}
										</span>
									</span>
								</span>
							</li>
						</ul>
						<div className="count-input rounded-pill d-md-none mt-3">
							<button
								type="button"
								className="btn btn-sm btn-icon"
								data-decrement
								aria-label="Decrement quantity"
								onClick={() => decreaseQuantity(product?.id)}
							>
								<i className="ci-minus"></i>
							</button>
							<input
								type="number"
								className="form-control form-control-sm"
								value={product?.quantity}
								readOnly
							/>
							<button
								type="button"
								className="btn btn-sm btn-icon"
								data-increment
								aria-label="Increment quantity"
								onClick={() => increaseQuantity(product?.id)}
							>
								<i className="ci-plus"></i>
							</button>
						</div>
					</div>
				</div>
			</td>
			<td className="h6 py-3 d-none d-xl-table-cell">
				<div className="d-flex flex-column">
					<span>{Number(product?.price).toFixed(2)} ₼ </span>
					<span>
						{product?.oldPrice != 0 && (
							<del className="text-body-tertiary fs-sm fw-normal">
								{Number(product?.oldPrice).toFixed(2)} ₼
							</del>
						)}
					</span>
				</div>
			</td>
			<td className="py-3 d-none d-md-table-cell">
				<div className="count-input rounded-pill">
					<button
						type="button"
						className="btn btn-icon"
						data-decrement
						aria-label="Decrement quantity"
						onClick={() => decreaseQuantity(product?.id)}
					>
						<i className="ci-minus"></i>
					</button>
					<input
						type="number"
						className="form-control"
						value={product?.quantity}
						readOnly
					/>
					<button
						type="button"
						className="btn btn-icon"
						data-increment
						aria-label="Increment quantity"
						onClick={() => increaseQuantity(product?.id)}
					>
						<i className="ci-plus"></i>
					</button>
				</div>
			</td>
			<td className="h6 py-3 d-none d-md-table-cell">
				{Number(product?.price * product?.quantity).toFixed(2)} ₼
			</td>
			<td className="text-end py-3 px-0">
				<button
					type="button"
					className="btn-close fs-sm"
					data-bs-toggle="tooltip"
					data-bs-custom-class="tooltip-sm"
					data-bs-title="Remove"
					aria-label="Remove from cart"
					onClick={() => removeFromCart(product?.id)}
				></button>
			</td>
		</tr>
	);
};

export default CartPageItem;

import axios from "axios";

// Base configuration for all Axios instances
const BASE_CONFIG = {
	timeout: process.env.REACT_APP_API_TIMEOUT || 30000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
};

// Utility function to create Axios instances
const createApiInstance = (baseURL) => {
	const instance = axios.create({
		...BASE_CONFIG,
		baseURL,
	});

	// Attach a new AbortController for each request
	instance.interceptors.request.use(
		(config) => {
			// Add AbortController if not already present
			if (!config.signal) {
				const controller = new AbortController();
				config.signal = controller.signal;
				config.abortController = controller; // Attach controller for external access
			}
			return config;
		},
		(error) => Promise.reject(error)
	);

	// Global Response Handling
	instance.interceptors.response.use(
		(response) => response.data,
		(error) => {
			if (error.response) {
				console.error("API Error:", error.response.data);

				switch (error.response.status) {
					case 401:
						localStorage.removeItem("token");
						window.location.href = "/login";
						break;
					case 403:
						console.error("Forbidden access");
						break;
					case 404:
						console.error("Resource not found");
						break;
					case 500:
						console.error("Server error");
						break;
					default:
						break;
				}
			} else if (error.request) {
				console.error("Network Error:", error.request);
			} else {
				console.error("Error:", error.message);
			}
			return Promise.reject(error);
		}
	);

	return instance;
};

// Public API (No Auth Required)
export const publicApi = createApiInstance(
	`${process.env.REACT_APP_API_BASE_URL}/store`
);

// Private API (Auth Required)
export const privateApi = createApiInstance(
	`${process.env.REACT_APP_API_BASE_URL}/`
);

// Auth API (Authentication Endpoints)
export const authApi = createApiInstance(
	`${process.env.REACT_APP_API_BASE_URL}/auth`
);

// Add Authorization Token for Private API
privateApi.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

// Utility function to cancel all requests
export const cancelAllRequests = (apiInstance) => {
	if (apiInstance && apiInstance.defaults.signal) {
		apiInstance.defaults.abortController.abort();
	}
};

import { useState, useEffect } from "react";

/**
 * Custom React hook for managing localStorage state.
 *
 * @param {string} key - The key under which the value is stored in localStorage.
 * @param {*} initialValue - The initial value to use if the key is not found in localStorage.
 * @returns {[any, function]} - The current value and a function to update it.
 */
function useLocalStorage(key, initialValue) {
	// State to store the value
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error("Error accessing localStorage:", error);
			return initialValue;
		}
	});

	// Function to update the value in localStorage
	const setValue = (value) => {
		try {
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.error("Error setting localStorage key:", error);
		}
	};

	// Sync state when localStorage changes in other tabs/windows
	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === key) {
				setStoredValue(
					event.newValue ? JSON.parse(event.newValue) : initialValue
				);
			}
		};

		window.addEventListener("storage", handleStorageChange);
		return () => window.removeEventListener("storage", handleStorageChange);
	}, [key, initialValue]);

	return [storedValue, setValue];
}

export default useLocalStorage;

import React from "react";

const FeaturesSection = () => {
	return (
		<section className="container pt-5 mt-1 mt-sm-3 mt-lg-4">
			<div className="row row-cols-2 row-cols-md-4 g-4">
				{/* <!-- Item --> */}
				<div className="col">
					<div className="d-flex flex-column flex-xxl-row align-items-center">
						<div className="d-flex text-dark-emphasis bg-body-tertiary rounded-circle p-4 mb-3 mb-xxl-0">
							<i className="ci-delivery fs-2 m-xxl-1"></i>
						</div>
						<div className="text-center text-xxl-start ps-xxl-3">
							<h3 className="h6 mb-1">Pulsuz çatdırım</h3>
							<p className="fs-sm mb-0">199 AZN-dən yuxarı</p>
						</div>
					</div>
				</div>

				{/* <!-- Item --> */}
				<div className="col">
					<div className="d-flex flex-column flex-xxl-row align-items-center">
						<div className="d-flex text-dark-emphasis bg-body-tertiary rounded-circle p-4 mb-3 mb-xxl-0">
							<i className="ci-credit-card fs-2 m-xxl-1"></i>
						</div>
						<div className="text-center text-xxl-start ps-xxl-3">
							<h3 className="h6 mb-1">Təhlükəsiz Ödəniş</h3>
							<p className="fs-sm mb-0">Ödəniş üçün təhlükəsiz</p>
						</div>
					</div>
				</div>

				{/* <!-- Item --> */}
				<div className="col">
					<div className="d-flex flex-column flex-xxl-row align-items-center">
						<div className="d-flex text-dark-emphasis bg-body-tertiary rounded-circle p-4 mb-3 mb-xxl-0">
							<i className="ci-refresh-cw fs-2 m-xxl-1"></i>
						</div>
						<div className="text-center text-xxl-start ps-xxl-3">
							<h3 className="h6 mb-1">30 gün ərzində Qaytarma</h3>
							<p className="fs-sm mb-0">Qaytarma üçün 30 gün</p>
						</div>
					</div>
				</div>

				{/* <!-- Item --> */}
				<div className="col">
					<div className="d-flex flex-column flex-xxl-row align-items-center">
						<div className="d-flex text-dark-emphasis bg-body-tertiary rounded-circle p-4 mb-3 mb-xxl-0">
							<i className="ci-chat fs-2 m-xxl-1"></i>
						</div>
						<div className="text-center text-xxl-start ps-xxl-3">
							<h3 className="h6 mb-1">24/7 Müştəri Dəstəyi</h3>
							<p className="fs-sm mb-0">Müştəri dəstəyi</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FeaturesSection;

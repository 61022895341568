import React, { useState } from "react";

const PaymentMethods = () => {
	return (
		<>
			<h2 className="h5 mt-5 mb-0">Ödəniş növü</h2>
			<div id="paymentMethod" role="list">
				{/* Cash on delivery */}
				<div className="mt-4">
					<div
						className="form-check mb-0"
						role="listitem"
						data-bs-toggle="collapse"
						data-bs-target="#cash"
						aria-expanded="true"
						aria-controls="cash"
					>
						<label className="form-check-label w-100 text-dark-emphasis fw-semibold">
							<input
								type="radio"
								className="form-check-input fs-base me-2 me-sm-3"
								name="payment-method"
								defaultChecked
							/>
							Nağd ödəniş
						</label>
					</div>
					{/* <div className="collapse show" id="cash" data-bs-parent="#paymentMethod">
						<div className="d-sm-flex align-items-center pt-3 pt-sm-4 pb-2 ps-3 ms-2 ms-sm-3">
							<span className="fs-sm me-3">I would require a change from:</span>
							<div
								className="input-group mt-2 mt-sm-0"
								style={{ maxWidth: "150px" }}
							>
								<span className="input-group-text rounded-pill rounded-end-0">
									<i className="ci-dollar-sign"></i>
								</span>
								<input
									type="number"
									className="form-control rounded-pill rounded-start-0"
									aria-label="Amount (to the nearest dollar)"
								/>
							</div>
						</div>
					</div> */}
				</div>

				{/* Credit card */}
				<div className="mt-4">
					<div
						className="form-check mb-0"
						role="listitem"
						data-bs-toggle="collapse"
						data-bs-target="#card"
						aria-expanded="false"
						aria-controls="card"
					>
						<label className="form-check-label d-flex align-items-center text-dark-emphasis fw-semibold">
							<input
								type="radio"
								className="form-check-input fs-base me-2 me-sm-3"
								name="payment-method"
							/>
							Kredit kartı
							<span className="d-none d-sm-flex gap-2 ms-3">
								<img
									src="assets/img/payment-methods/visa-light-mode.svg"
									className="d-none-dark"
									width="36"
									alt="Visa"
								/>
								<img
									src="assets/img/payment-methods/visa-dark-mode.svg"
									className="d-none d-block-dark"
									width="36"
									alt="Visa"
								/>
								<img
									src="assets/img/payment-methods/mastercard.svg"
									width="36"
									alt="Mastercard"
								/>
							</span>
						</label>
					</div>
					<div className="collapse" id="card" data-bs-parent="#paymentMethod">
						<form
							className="needs-validation pt-4 pb-2 ps-3 ms-2 ms-sm-3"
							noValidate
						>
							<div
								className="position-relative mb-3 mb-sm-4"
								data-input-format='{"creditCard": true}'
							>
								<input
									type="text"
									className="form-control form-control-lg form-icon-end"
									placeholder="Kart nömrəsi"
									required
								/>
								<span
									className="position-absolute d-flex top-50 end-0 translate-middle-y fs-5 text-body-tertiary me-3"
									data-card-icon=""
								></span>
							</div>
							<div className="row row-cols-1 row-cols-sm-2 g-3 g-sm-4">
								<div className="col">
									<input
										type="text"
										className="form-control form-control-lg"
										placeholder="MM/YY"
									/>
								</div>
								<div className="col">
									<input
										type="text"
										className="form-control form-control-lg"
										maxLength="4"
										placeholder="CVC"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>

				{/* Google Pay */}
				<div className="mt-4">
					<div
						className="form-check mb-0"
						role="listitem"
						data-bs-toggle="collapse"
						data-bs-target="#googlepay"
						aria-expanded="false"
						aria-controls="googlepay"
					>
						<label className="form-check-label d-flex align-items-center text-dark-emphasis fw-semibold">
							<input
								type="radio"
								className="form-check-input fs-base me-2 me-sm-3"
								name="payment-method"
							/>
							Google Pay
							<img
								src="assets/img/payment-methods/google-icon.svg"
								className="ms-3"
								width="20"
								alt="Google Pay"
							/>
						</label>
					</div>
					<div
						className="collapse"
						id="googlepay"
						data-bs-parent="#paymentMethod"
					></div>
				</div>

				{/* Card on delivery */}
				{/* <div className="mt-4">
					<div
						className="form-check mb-0"
						role="listitem"
						data-bs-toggle="collapse"
						data-bs-target="#card-on-delivery"
						aria-expanded="false"
						aria-controls="card-on-delivery"
					>
						<label className="form-check-label text-dark-emphasis fw-semibold">
							<input
								type="radio"
								className="form-check-input fs-base me-2 me-sm-3"
								name="payment-method"
							/>
							Terminal ilə ödəniş
						</label>
					</div>
					<div
						className="collapse"
						id="card-on-delivery"
						data-bs-parent="#paymentMethod"
					></div>
				</div> */}
			</div>
		</>
	);
};

export default PaymentMethods;

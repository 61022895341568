import React from "react";
import { Link } from "react-router-dom";
import HeroSection from "../components/sections/HeroSection";
import FeaturesSection from "../components/sections/FeaturesSection";
import ProductsSection from "../components/sections/ProductsSection";
import BrandsSection from "../components/sections/BrandsSection";
import SubscriptionSection from "../components/sections/SubscriptionSection";

const HomePage = () => {
	return (
		<>
			{/* <!-- Hero slider --> */}
			<HeroSection />

			{/* <!-- Features --> */}
			<FeaturesSection />

			{/* <!-- New arrivals (List) --> */}
			{/* <NewArrivalsSection /> */}

			{/* <!-- Trending products (Grid) --> */}
			<ProductsSection
				title="Trend məhsullar"
				path="/products"
				linkText="Hamısına bax"
			/>

			{/* <!-- Sale Banner (CTA) --> */}
			{/* <SaleBannerSection /> */}

			{/* <!-- Special offers (Carousel) --> */}
			{/* <ProductsSliderSection /> */}

			{/* <!-- Brands --> */}
			{/* <BrandsSection /> */}

			{/* <!-- Subscription form + Vlog --> */}
			{/* <SubscriptionSection /> */}
		</>
	);
};

export default HomePage;

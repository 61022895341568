import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Thumbs } from "swiper/modules";
import { productService } from "../services/productService";
import { Link, useParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import { useCartContext } from "../contexts/CartContext";

const RelatedProducts = ({ relatedProducts }) => {
	if (!relatedProducts) return null;

	return (
		<>
			<h2 className="h5 pt-5">Related products</h2>
			<div className="d-flex border rounded-5 px-2 mb-4">
				<div className="w-50">
					<div className="card product-card h-100 bg-transparent border-0 shadow-none">
						<div className="position-relative z-2">
							<button
								type="button"
								className="btn btn-icon btn-sm btn-secondary animate-pulse fs-sm bg-body border-0 position-absolute top-0 end-0 z-2 mt-1 mt-sm-2 me-1 me-sm-2"
								aria-label="Add to Wishlist"
							>
								<i className="ci-heart animate-target"></i>
							</button>
							<a className="d-block p-2 p-lg-3" href="#!">
								<div
									className="ratio"
									style={{
										"--cz-aspect-ratio": "calc(160 / 191 * 100%)",
									}}
								>
									<img src="assets/img/shop/grocery/12.png" alt="Image" />
								</div>
							</a>
							<div className="position-absolute w-100 start-0 bottom-0">
								<div className="d-flex justify-content-end px-2 px-lg-3 pb-2 pb-lg-3">
									<div className="count-input count-input-collapsible collapsed justify-content-between w-100 bg-transparent border-0 rounded-2">
										<button
											type="button"
											className="btn btn-icon btn-sm btn-primary"
											data-decrement=""
											aria-label="Decrement quantity"
										>
											<i className="ci-minus fs-sm"></i>
										</button>
										<input
											type="number"
											className="form-control form-control-sm bg-primary text-white w-100"
											value="0"
											min="0"
											readOnly
										/>
										<button
											type="button"
											className="product-card-button btn btn-icon btn-sm btn-secondary ms-auto"
											data-increment=""
											aria-label="Increment quantity"
										>
											<span data-count-input-value=""></span>
											<i className="ci-plus fs-sm"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body pt-0 px-1 px-md-2 px-lg-3 pb-2">
							<div className="h6 mb-2">$2.59</div>
							<h3 className="fs-sm lh-base mb-0">
								<a className="hover-effect-underline fw-normal" href="#!">
									Soft creme cheese with greens Exquisa
								</a>
							</h3>
						</div>
						<div className="fs-xs text-body-secondary px-1 px-md-2 px-lg-3 pb-2 pb-md-3">
							150g
						</div>
					</div>
				</div>
				<hr className="vr my-0 mx-2" />
				<div className="w-50">
					<div className="card product-card h-100 bg-transparent border-0 shadow-none">
						<div className="position-relative z-2">
							<button
								type="button"
								className="btn btn-icon btn-sm btn-secondary animate-pulse fs-sm bg-body border-0 position-absolute top-0 end-0 z-2 mt-1 mt-sm-2 me-1 me-sm-2"
								aria-label="Add to Wishlist"
							>
								<i className="ci-heart animate-target"></i>
							</button>
							<a className="d-block p-2 p-lg-3" href="#!">
								<div
									className="ratio"
									style={{
										"--cz-aspect-ratio": "calc(160 / 191 * 100%)",
									}}
								>
									<img src="assets/img/shop/grocery/11.png" alt="Image" />
								</div>
							</a>
							<div className="position-absolute w-100 start-0 bottom-0">
								<div className="d-flex justify-content-end px-2 px-lg-3 pb-2 pb-lg-3">
									<div className="count-input count-input-collapsible collapsed justify-content-between w-100 bg-transparent border-0 rounded-2">
										<button
											type="button"
											className="btn btn-icon btn-sm btn-primary"
											data-decrement=""
											aria-label="Decrement quantity"
										>
											<i className="ci-minus fs-sm"></i>
										</button>
										<input
											type="number"
											className="form-control form-control-sm bg-primary text-white w-100"
											value="0"
											min="0"
											readOnly
										/>
										<button
											type="button"
											className="product-card-button btn btn-icon btn-sm btn-secondary ms-auto"
											data-increment=""
											aria-label="Increment quantity"
										>
											<span data-count-input-value=""></span>
											<i className="ci-plus fs-sm"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body pt-0 px-1 px-md-2 px-lg-3 pb-2">
							<div className="h6 mb-2">$3.40</div>
							<h3 className="fs-sm lh-base mb-0">
								<a className="hover-effect-underline fw-normal" href="#!">
									Fresh red grapefruit
								</a>
							</h3>
						</div>
						<div className="fs-xs text-body-secondary px-1 px-md-2 px-lg-3 pb-2 pb-md-3">
							1kg
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const ProductInfo = ({ product }) => {
	if (!product) return null;

	return (
		<>
			<div
				className="accordion accordion-alt-icon py-2 mb-4"
				id="productAccordion"
			>
				<div className="accordion-item">
					<h3 className="accordion-header" id="headingProductInfo">
						<button
							type="button"
							className="accordion-button animate-underline collapsed"
							data-bs-toggle="collapse"
							data-bs-target="#productInfo"
							aria-expanded="false"
							aria-controls="productInfo"
						>
							<span className="animate-target me-2">Məhsul haqqında</span>
						</button>
					</h3>
					<div
						className="accordion-collapse collapse"
						id="productInfo"
						aria-labelledby="headingProductInfo"
						data-bs-parent="#productAccordion"
					>
						<div className="accordion-body">{product?.description}</div>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header" id="headingProductDelivery">
						<button
							type="button"
							className="accordion-button animate-underline collapsed"
							data-bs-toggle="collapse"
							data-bs-target="#productDelivery"
							aria-expanded="false"
							aria-controls="productDelivery"
						>
							<span className="animate-target me-2">
								Çatdırılma məlumatları
							</span>
						</button>
					</h3>
					<div
						className="accordion-collapse collapse"
						id="productDelivery"
						aria-labelledby="headingProductDelivery"
						data-bs-parent="#productAccordion"
					>
						<div className="accordion-body">
							<ul className="m-0">
								<li>
									Enjoy free delivery for orders{" "}
									<span className="text-body-emphasis fw-medium">
										over $50!
									</span>
								</li>
								<li>
									For orders{" "}
									<span className="text-body-emphasis fw-medium">
										below $50
									</span>
									, a standard delivery fee of{" "}
									<span className="text-body-emphasis fw-medium">$5</span>{" "}
									applies.
								</li>
								<li>
									We strive to deliver your order in a timely manner to ensure
									your satisfaction.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const DetailsPage = () => {
	const { id } = useParams();
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const { addToCart, increaseQuantity, decreaseQuantity, getProductQuantity } =
		useCartContext();

	const { data: product, isLoading: productLoading } = useApi(
		["product", id],
		() => productService.getProduct(id)
	);

	return (
		<>
			{/* <!-- Breadcrumb --> */}
			<nav
				className="container position-relative z-2 pt-lg-2 mt-3 mt-lg-4"
				aria-label="breadcrumb"
			>
				<ol className="breadcrumb mb-0">
					<li className="breadcrumb-item">
						<a href="home-grocery.html">Home</a>
					</li>
					<li className="breadcrumb-item">
						<a href="shop-catalog-grocery.html">Shop</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						Product page
					</li>
				</ol>
			</nav>
			{/* <!-- Product gallery + Product details --> */}
			<section className="container pt-md-4 pb-5 mt-md-2 mt-lg-3 mb-2 mb-sm-3 mb-lg-4 mb-xl-5">
				<div className="row align-items-start">
					{/* <!-- Product gallery --> */}
					<div
						className="col-md-6 col-lg-7 sticky-md-top z-1 mb-4 mb-md-0"
						style={{ marginTop: "-120px" }}
					>
						<div className="d-flex" style={{ paddingTop: "120px" }}>
							{/* <!-- Thumbnails --> */}
							<Swiper
								onSwiper={setThumbsSwiper}
								direction="vertical"
								spaceBetween={12}
								slidesPerView={4}
								watchSlidesProgress
								modules={[Thumbs]}
								className="swiper swiper-load swiper-thumbs d-none d-lg-block w-100 me-xl-3"
								style={{ maxWidth: "96px", height: "420px" }}
							>
								{product?.images.map((image, index) => (
									<SwiperSlide className="swiper-thumb" key={index}>
										<div
											className="ratio ratio-1x1"
											style={{ maxWidth: "94px" }}
										>
											<img
												src={`${image}thumbnail.webp`}
												className="swiper-thumb-img object-fit-contain"
												alt="Thumbnail"
											/>
										</div>
									</SwiperSlide>
								))}
							</Swiper>

							{/* <!-- Preview (Large image) --> */}
							<Swiper
								loop={false}
								thumbs={{
									swiper:
										thumbsSwiper && !thumbsSwiper.destroyed
											? thumbsSwiper
											: null,
								}}
								modules={[Thumbs, Pagination]}
								className="swiper w-100"
							>
								{product?.images.map((image, index) => (
									<SwiperSlide key={index}>
										<Link
											className="ratio ratio-1x1  d-block cursor-zoom-in"
											to={`${image}medium.webp`}
											data-glightbox=""
											data-gallery="product-gallery"
										>
											<img
												src={`${image}medium.webp`}
												alt="Preview"
												className="object-fit-contain"
											/>
										</Link>
									</SwiperSlide>
								))}
								<div className="swiper-pagination mb-n3 d-lg-none"></div>
							</Swiper>
						</div>
					</div>

					{/* <!-- Product details --> */}
					<div className="col-md-6 col-lg-5 position-relative">
						<div className="ps-xxl-3">
							{/* <!-- Reviews --> */}
							<a
								className="d-none d-md-flex align-items-center gap-2 text-decoration-none mb-3"
								href="#reviews"
							>
								<div className="d-flex gap-1 fs-sm">
									{/* <i className="ci-star-filled text-warning"></i> */}
									<i className="ci-star text-body-tertiary opacity-75"></i>
									<i className="ci-star text-body-tertiary opacity-75"></i>
									<i className="ci-star text-body-tertiary opacity-75"></i>
									<i className="ci-star text-body-tertiary opacity-75"></i>
									<i className="ci-star text-body-tertiary opacity-75"></i>
								</div>
								<span className="text-body-tertiary fs-sm">0 rəy</span>
							</a>

							{/* <!-- Title --> */}
							<h1 className="h3">{product?.name}</h1>

							{/* <!-- Description --> */}
							<p className="fs-sm mb-4"></p>

							{/* <!-- Price -->   */}
							<div className="h4 d-flex align-items-center my-4">
								{Number(product?.price).toFixed(2)} AZN
								{!product?.oldPrice && (
									<del className="fs-sm fw-normal text-body-tertiary ms-2">
										{Number(product?.oldPrice).toFixed(2)} AZN
									</del>
								)}
							</div>

							{/* <!-- Count + Buttons --> */}
							<div className="d-flex flex-no-wrap gap-3 gap-lg-2 gap-xl-3 mb-4">
								{getProductQuantity(product?.id) > 0 && (
									<div className="count-input flex-shrink-0 order-1">
										<button
											type="button"
											className="btn btn-icon btn-lg"
											data-decrement=""
											aria-label="Decrement quantity"
											onClick={() => decreaseQuantity(product?.id)}
										>
											<i className="ci-minus"></i>
										</button>
										<input
											type="number"
											className="flex-grow-1 form-control form-control-lg"
											value={getProductQuantity(product?.id)}
											readOnly
										/>
										<button
											type="button"
											className="btn btn-icon btn-lg"
											data-increment=""
											aria-label="Increment quantity"
											onClick={() => increaseQuantity(product?.id)}
										>
											<i className="ci-plus"></i>
										</button>
									</div>
								)}
								<button
									type="button"
									className="btn btn-icon btn-lg btn-secondary animate-pulse order-2"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									data-bs-title="Add to Wishlist"
									aria-label="Add to Wishlist"
								>
									<i className="ci-heart fs-lg animate-target"></i>
								</button>
								<button
									type="button"
									className="btn btn-icon btn-lg btn-secondary animate-rotate order-3"
									data-bs-toggle="tooltip"
									data-bs-placement="top"
									data-bs-title="Compare"
									aria-label="Compare"
								>
									<i className="ci-refresh-cw fs-lg animate-target"></i>
								</button>
								{getProductQuantity(product?.id) <= 0 && (
									<button
										onClick={() => addToCart(product)}
										type="button"
										className="btn btn-lg btn-primary w-100 animate-slide-end "
									>
										<i className="ci-shopping-cart fs-lg animate-target ms-n1 me-2"></i>
										Səbətə əlavə et
									</button>
								)}
							</div>

							{/* <!-- Related products --> */}
							<RelatedProducts />

							{/* <!-- Product info accordion --> */}
							<ProductInfo product={product} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DetailsPage;

import { createContext, useState, useContext, useCallback } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [cart, setCart] = useLocalStorage("cart", []);

	const addToCart = useCallback(
		(product) => {
			const existingProduct = cart.find((item) => item.id === product.id);

			if (existingProduct) {
				setCart(
					cart.map((item) =>
						item.id === product.id
							? { ...item, quantity: item.quantity + 1 }
							: item
					)
				);
			} else {
				setCart([...cart, { ...product, quantity: 1 }]);
			}
		},
		[cart]
	);

	const removeFromCart = useCallback(
		(productId) => {
			setCart(cart.filter((item) => item.id !== productId));
		},
		[cart]
	);

	const clearCart = useCallback(() => {
		setCart([]);
	}, [cart]);

	const getCartTotal = useCallback(() => {
		return cart.reduce((total, item) => total + item.price * item.quantity, 0);
	}, [cart]);

	const getCartQuantity = useCallback(() => {
		return cart.reduce((total, item) => total + item.quantity, 0);
	}, [cart]);

	const getProductQuantity = useCallback(
		(productId) => {
			const item = cart.find((item) => item.id === productId);
			return item ? item.quantity : 0;
		},
		[cart]
	);

	const increaseQuantity = useCallback(
		(productId) => {
			setCart(
				cart.map((item) =>
					item.id === productId
						? { ...item, quantity: item.quantity + 1 }
						: item
				)
			);
		},
		[cart]
	);

	const decreaseQuantity = useCallback(
		(productId) => {
			setCart(
				cart
					.map((item) =>
						item.id === productId
							? { ...item, quantity: item.quantity - 1 }
							: item
					)
					.filter((item) => item.quantity > 0)
			);
		},
		[cart]
	);

	const isCartEmpty = useCallback(() => {
		return cart.length === 0;
	}, [cart]);

	const openCart = useCallback(() => setIsCartOpen(true), [isCartOpen]);
	const closeCart = useCallback(() => setIsCartOpen(false), [isCartOpen]);

	return (
		<CartContext.Provider
			value={{
				cart,
				addToCart,
				removeFromCart,
				clearCart,
				getCartTotal,
				getCartQuantity,
				increaseQuantity,
				decreaseQuantity,
				isCartEmpty,
				isCartOpen,
				getProductQuantity,
				openCart,
				closeCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export const useCartContext = () => {
	return useContext(CartContext);
};

import React, { useState, useEffect } from "react";

// Constants for theme storage key and valid themes
const THEME_STORAGE_KEY = "theme";
const VALID_THEMES = ["light", "dark", "auto"];

/**
 * ThemeSwitcher Component
 * - Manages theme state with localStorage persistence
 * - Provides dropdown UI for selecting themes
 * - Supports system theme changes in 'auto' mode
 */
const ThemeSwitcher = () => {
	// Initialize theme from localStorage or system preference
	const getInitialTheme = () => {
		const storedTheme = localStorage.getItem(THEME_STORAGE_KEY);
		if (VALID_THEMES.includes(storedTheme)) {
			return storedTheme;
		}
		return window.matchMedia("(prefers-color-scheme: dark)").matches
			? "dark"
			: "light";
	};

	const [theme, setThemeState] = useState(getInitialTheme);

	// Apply theme to document root
	const applyTheme = (selectedTheme) => {
		let effectiveTheme = selectedTheme;

		if (selectedTheme === "auto") {
			effectiveTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
				? "dark"
				: "light";
		}

		document.documentElement.setAttribute("data-bs-theme", effectiveTheme);
		localStorage.setItem(THEME_STORAGE_KEY, selectedTheme);
	};

	// Effect to apply theme when it changes
	useEffect(() => {
		applyTheme(theme);
	}, [theme]);

	// Listen for system theme changes if 'auto' is selected
	useEffect(() => {
		const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

		const handleSystemThemeChange = () => {
			if (theme === "auto") {
				applyTheme("auto");
			}
		};

		mediaQuery.addEventListener("change", handleSystemThemeChange);
		return () =>
			mediaQuery.removeEventListener("change", handleSystemThemeChange);
	}, [theme]);

	// Handle theme change
	const handleThemeChange = (newTheme) => {
		if (VALID_THEMES.includes(newTheme)) {
			setThemeState(newTheme);
		} else {
			console.warn(`Invalid theme: ${newTheme}`);
		}
	};

	return (
		<div className="dropdown">
			{/* Dropdown Toggle Button */}
			<button
				type="button"
				className="theme-switcher btn btn-icon btn-lg btn-outline-secondary fs-lg border-0 rounded-circle animate-scale"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				aria-label={`Toggle theme (${theme})`}
			>
				<span className="theme-icon-active d-flex animate-target">
					{theme === "light" && <i className="ci-sun"></i>}
					{theme === "dark" && <i className="ci-moon"></i>}
					{theme === "auto" && <i className="ci-auto"></i>}
				</span>
			</button>

			{/* Dropdown Menu */}
			<ul
				className="dropdown-menu"
				style={{ "--cz-dropdown-min-width": "9rem" }}
			>
				{/* Light Theme */}
				<li>
					<button
						type="button"
						className={`dropdown-item ${theme === "light" ? "active" : ""}`}
						onClick={() => handleThemeChange("light")}
						aria-pressed={theme === "light"}
					>
						<span className="theme-icon d-flex fs-base me-2">
							<i className="ci-sun"></i>
						</span>
						<span className="theme-label">Açıq</span>
						{theme === "light" && (
							<i className="item-active-indicator ci-check ms-auto"></i>
						)}
					</button>
				</li>

				{/* Dark Theme */}
				<li>
					<button
						type="button"
						className={`dropdown-item ${theme === "dark" ? "active" : ""}`}
						onClick={() => handleThemeChange("dark")}
						aria-pressed={theme === "dark"}
					>
						<span className="theme-icon d-flex fs-base me-2">
							<i className="ci-moon"></i>
						</span>
						<span className="theme-label">Tünd</span>
						{theme === "dark" && (
							<i className="item-active-indicator ci-check ms-auto"></i>
						)}
					</button>
				</li>

				{/* Auto Theme */}
				<li>
					<button
						type="button"
						className={`dropdown-item ${theme === "auto" ? "active" : ""}`}
						onClick={() => handleThemeChange("auto")}
						aria-pressed={theme === "auto"}
					>
						<span className="theme-icon d-flex fs-base me-2">
							<i className="ci-auto"></i>
						</span>
						<span className="theme-label">Avtomatik</span>
						{theme === "auto" && (
							<i className="item-active-indicator ci-check ms-auto"></i>
						)}
					</button>
				</li>
			</ul>
		</div>
	);
};

export default ThemeSwitcher;

import { Link } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
const ProductCard = ({ product }) => {
	const {
		id,
		name,
		price,
		image,
		description,
		category,
		rating,
		reviews,
		features,
		oldPrice,
	} = product;

	const { addToCart } = useCartContext();

	return (
		<div
			key={id}
			className="product-card animate-underline hover-effect-opacity bg-body rounded"
		>
			<div className="position-relative">
				<div className="position-absolute top-0 end-0 z-2 hover-effect-target opacity-0 mt-3 me-3">
					<div className="d-flex flex-column gap-2">
						<button
							type="button"
							className="btn btn-icon btn-secondary animate-pulse d-none d-lg-inline-flex"
							aria-label="Add to Wishlist"
						>
							<i className="ci-heart fs-base animate-target"></i>
						</button>
						<button
							type="button"
							className="btn btn-icon btn-secondary animate-rotate d-none d-lg-inline-flex"
							aria-label="Compare"
						>
							<i className="ci-refresh-cw fs-base animate-target"></i>
						</button>
					</div>
				</div>
				<div className="dropdown d-lg-none position-absolute top-0 end-0 z-2 mt-2 me-2">
					<button
						type="button"
						className="btn btn-icon btn-sm btn-secondary bg-body"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						aria-label="More actions"
					>
						<i className="ci-more-vertical fs-lg"></i>
					</button>
					<ul
						className="dropdown-menu dropdown-menu-end fs-xs p-2"
						style={{ minWidth: "auto" }}
					>
						<li>
							<a className="dropdown-item" href="#!">
								<i className="ci-heart fs-sm ms-n1 me-2"></i>
								Add to Wishlist
							</a>
						</li>
						<li>
							<a className="dropdown-item" href="#!">
								<i className="ci-refresh-cw fs-sm ms-n1 me-2"></i>
								Compare
							</a>
						</li>
					</ul>
				</div>
				<Link
					className="d-block rounded-top overflow-hidden p-3 p-sm-4"
					to={`/products/${id}`}
				>
					{price < oldPrice && (
						<span className="badge bg-danger position-absolute top-0 start-0 mt-2 ms-2 mt-lg-3 ms-lg-3 z-1">
							-{Math.round(((oldPrice - price) / oldPrice) * 100)}%
						</span>
					)}
					<div
						className="ratio"
						style={{
							"--cz-aspect-ratio": "calc(240 / 258 * 100%)",
						}}
					>
						{image ? (
							<img
								src={`${image}medium.webp`}
								alt={name}
								className="object-fit-contain"
							/>
						) : (
							<img
								src={"assets/img/default.jpg"}
								alt={name}
								className="object-fit-contain"
							/>
						)}
					</div>
				</Link>
			</div>
			<div className="w-100 min-w-0 px-1 pb-2 px-sm-3 pb-sm-3">
				<div className="d-flex align-items-center gap-2 mb-2">
					<div className="d-flex gap-1 fs-xs">
						{/* <i className="ci-star-filled text-warning"></i>
						<i className="ci-star-filled text-warning"></i>
						<i className="ci-star-filled text-warning"></i>
						<i className="ci-star-filled text-warning"></i> */}
						<i className="ci-star text-body-tertiary opacity-75"></i>
						<i className="ci-star text-body-tertiary opacity-75"></i>
						<i className="ci-star text-body-tertiary opacity-75"></i>
						<i className="ci-star text-body-tertiary opacity-75"></i>
						<i className="ci-star text-body-tertiary opacity-75"></i>
					</div>
					<span className="text-body-tertiary fs-xs">({reviews || 0})</span>
				</div>
				<h3 className="pb-1 mb-2">
					<Link
						className="d-block fs-sm fw-medium text-truncate"
						to={`/products/${id}`}
					>
						<span className="animate-target">{name}</span>
					</Link>
				</h3>
				<div className="d-flex align-items-center justify-content-between">
					<div className="h5 lh-1 mb-0">
						${Number(price).toFixed(2)}{" "}
						{oldPrice != 0 && (
							<del className="text-body-tertiary fs-sm fw-normal">
								${Number(oldPrice).toFixed(2)}
							</del>
						)}
					</div>
					<button
						type="button"
						className="product-card-button btn btn-icon btn-secondary animate-slide-end ms-2"
						aria-label="Add to Cart"
						onClick={() => addToCart(product)}
					>
						<i className="ci-shopping-cart fs-base animate-target"></i>
					</button>
				</div>

				{/* <!-- Progress bar --> */}
				{/* <div
					className="progress mb-2"
					role="progressbar"
					aria-label="Available in stock"
					aria-valuenow="25"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{ height: "4px" }}
				>
					<div
						className="progress-bar rounded-pill"
						style={{ width: "25%" }}
					></div>
				</div>
				<div className="text-body-secondary fs-sm">
					Available: <span className="text-dark-emphasis fw-medium">112</span>
				</div> */}
			</div>
			{features && (
				<div className="product-card-details position-absolute top-100 start-0 w-100 bg-body rounded-bottom shadow mt-n2 p-3 pt-1">
					<span className="position-absolute top-0 start-0 w-100 bg-body mt-n2 py-2"></span>
					<ul className="list-unstyled d-flex flex-column gap-2 m-0">
						{features?.map((feature) => (
							<li className="d-flex align-items-center">
								<span className="fs-xs">{feature.label}:</span>
								<span className="d-block flex-grow-1 border-bottom border-dashed px-1 mt-2 mx-2"></span>
								<span className="text-dark-emphasis fs-xs fw-medium text-end">
									{feature.value}
								</span>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ProductCard;
